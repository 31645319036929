import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../templates/layout"
import SEO from "../components/seo"
import Footer from "../components/footer"
import Img from "gatsby-image"
import Modal from "../components/modal"
import Card from "../components/card"

const Leadership = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allGraphCmsTeam {
          nodes {
            id
            name
            position
            description {
              html
              markdown
            }
            profileImage {
              handle
              width
              height
            }
          }
        }
      }
    `
  )

  const [isOpen, setIsOpen] = useState(false)
  const [profileID, setProfileID] = useState("")

  return (
    <Layout isHeaderFixed={false}>
      <SEO
        title="Leadership"
        description="Get fast and reliable solution for your digital problem, We got you covered with all software from Website development to ECommerce site to Mobile app to AI enabled drones."
      />
      <div className="pt-16 pb-24 mx-4">
        <div className="text-center font-extrabold text-black text-5xl mb-8 pt-16 font-display">
          Our leadership team
        </div>
        <div className="text-center text-2xl md:w-1/2 mx-auto">
          Successful companies begin with the right people. Meet those who
          motivate our team, respond to challenges and allow us to innovate and
          grow.
        </div>
      </div>

      <div className="max-w-screen-lg mx-auto items-center px-8 lg:px-0 relative py-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {data.allGraphCmsTeam.nodes.map(v => (
            <Card
              data={v}
              setOpen={setIsOpen}
              setActiveProfile={setProfileID}
            />
          ))}
        </div>
      </div>

      {isOpen && profileID !== "" && (
        <Modal
          profile={
            data.allGraphCmsTeam.nodes.filter(team => team.id === profileID)[0]
          }
          close={() => setIsOpen(false)}
        />
      )}

      <Footer />
    </Layout>
  )
}

export default Leadership
