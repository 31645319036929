import React, { useEffect } from "react"
import GraphImg from "graphcms-image"

import ReactMarkdown from "react-markdown"

const Modal = ({ profile, close }) => {
  useEffect(() => {
    document.body.style.overflow = "hidden"
    return () => {
      document.body.style.overflow = "unset"
    }
  }, [])

  return (
    <div class="fixed z-10 inset-0 overflow-y-scroll">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-black opacity-75"></div>
        </div>

        {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle max-w-screen-lg w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 relative ">
            <div
              onClick={() => close()}
              className="absolute right-4 top-4 cursor-pointer z-30"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M7 7l10 10"></path>
                <path d="M17 7L7 17"></path>
              </svg>
            </div>
            <div className="flex flex-wrap justify-between">
              <div className="w-full md:w-1/3">
                <GraphImg image={profile.profileImage} />
              </div>
              <div className="w-full md:w-3/5 mx-auto my-8 md:my-0">
                <div className="text-xl font-bold text-black mb-2">
                  {profile.name}
                </div>
                <div className="text-gray-700 mb-4">{profile.position}</div>
                <div className="text-black prose lg:prose-lg">
                  <ReactMarkdown>{profile.description.markdown}</ReactMarkdown>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal
