import React, { useState } from "react"
import GraphImg from "graphcms-image"

const Card = ({ data, setOpen, setActiveProfile }) => {
  const handleClick = () => {
    setActiveProfile(data.id)
    setOpen(true)
  }
  return (
    <div className="w-full mb-8">
      <div className="w-full bg-gray-100">
        <GraphImg image={data.profileImage} className="rounded" />
      </div>
      <div className="text-xl mt-8 text-gray-900 font-bold mb-1">
        {data.name}
      </div>
      <div className="text-gray-700">CEO and Co-Founder</div>
      <div className="mt-4 text-lg line-clamp-3">
        {data.description.markdown}
      </div>
      <div
        onClick={handleClick}
        className="mt-4 text-primary-500 cursor-pointer"
      >
        READ MORE
      </div>
    </div>
  )
}

export default Card
